import React from 'react';
import { Link } from 'gatsby';
import logo from '../../images/logo.svg';

const PorticaLogo = () => {
  return (
    <div className="portica-logo has-text-centered">
      <Link to="/">
        <img src={logo} alt="Portica logo" />
      </Link>
    </div>
  );
};

export default PorticaLogo;
