import React, { useEffect, useRef, useState } from 'react';
import Field from '../ui/field';
import WithLocation from '../with-location';
import { Link } from 'gatsby';
import { doLogin, isLoggedIn } from '../../services/user';
import { validateEmail } from '../../utils/validations';

import { getConsent, setConsent } from '../../services/consent';
import * as fromStorage from '../../services/storage';

const APP_URL = process.env.GATSBY_APP_URL;
const TASK_URL = process.env.GATSBY_TASKS_URL;

const SignInForm = (props) => {
  const emailField = useRef();
  const passwordField = useRef();
  const keepField = useRef();
  const [returnUrl, setReturnUrl] = useState('');
  const [returnTo, setReturnTo] = useState('');
  const [emailError, setEmailError] = useState(false);
  const [passwordError, setPasswordError] = useState(false);
  const [isWorking, setIsWorking] = useState(false);
  const [consentStatus, setConsentStatus] = useState(getConsent());

  let woopra = null;
  if (typeof window !== 'undefined') {
    woopra = window.woopra || null;
  }

  useEffect(() => {
    setReturnUrl(props.url);
    setReturnTo(props.from);
  }, []);

  const checkEmail = () => {
    const val = emailField.current.value;

    if (val.length === 0) {
      setEmailError('This is a required field.');
      return false;
    }
    if (!validateEmail(val)) {
      setEmailError('Must be a email address.');
      return false;
    }

    setEmailError(false);
    return true;
  };

  const checkPassword = () => {
    const val = passwordField.current.value;

    if (val.length === 0) {
      setPasswordError('This is a required field.');
      return false;
    }

    setPasswordError(false);
    return true;
  };

  const login = () => {
    /* const { redirectState, redirectParams } = props.search;
    let urlAddons = '';

    if (redirectState) {
      urlAddons = `?redirectState=${redirectState}`;
      if (redirectParams) {
        urlAddons += `&redirectParams=${redirectParams}`;
      }
    } */

    setIsWorking(true);

    const email = emailField.current.value;
    const password = passwordField.current.value;
    const keep = keepField.current.checked;
    const emailTest = checkEmail();
    const passwordTest = checkPassword();

    if (emailTest && passwordTest) {
      const result = doLogin(email, password, keep);

      result.then((data) => {
        const user = data.data.user;
        if (!consentStatus) {
          setConsent('all');
        }

        if (consentStatus === 'update-here') {
          if (woopra) {
            if (user) {
              fromStorage.setIdentified(user.email);
              woopra.identify({
                id: user.id,
                email: user.email,
                name: user.name,
              });

              woopra.push();
            }

            woopra.track('login_successful');
          }
        }

        let url;

        switch (returnTo) {
          case 'tasks':
            url = TASK_URL;
            break;
          case 'app':
            url = APP_URL;
            break;

          default:
            return props.navigate('/');
        }

        console.log(url);

        if (returnUrl) {
          url += decodeURIComponent(returnUrl);
        }

        window.location.href = url;
      });

      result.catch((err) => {
        if (consentStatus === 'update-here') {
          if (woopra) {
            woopra.track('login_failed', {
              error: err,
              email: emailField.current.value,
            });
          }
        }
        setPasswordError('Wrong email and password combination');
        setIsWorking(false);
        return;
      });
    }
  };

  const logged = isLoggedIn();
  if (logged) {
    return (
      <div className="sign-in-form">
        <div>
          <p className="already-logged has-text-centered">
            You're already logged in
          </p>
          <p className="logged-links has-text-centered">
            <Link to="/?logout=true">Logout</Link> |
            <Link to="/">Back Home</Link>
          </p>
        </div>
      </div>
    );
  }

  return (
    <div className="sign-in-form">
      <div>
        <h1 className="title is-size-4 has-text-centered">Sign in</h1>
        <Field>
          <div className="control">
            <input
              type="email"
              className={`input ${emailError ? 'is-danger' : ''}`}
              name="email"
              ref={emailField}
              onBlur={() => checkEmail()}
              placeholder="Your email"
              required
            ></input>
            {emailError ? <p className="help is-danger">{emailError}</p> : null}
          </div>
        </Field>
        <Field>
          <div className="control">
            <input
              type="password"
              className={`input ${passwordError ? 'is-danger' : ''}`}
              name="password"
              ref={passwordField}
              onBlur={() => checkPassword()}
              placeholder="Your password"
              required
            ></input>
            {passwordError ? (
              <p className="help is-danger">{passwordError}</p>
            ) : null}
          </div>
        </Field>
        <Field>
          <div className="control">
            <label className="checkbox">
              <input type="checkbox" ref={keepField} /> Remember me
            </label>
          </div>
        </Field>
        <Field>
          <div className="control">
            <button
              className="button is-primary is-fullwidth"
              onClick={() => login()}
              disabled={isWorking}
            >
              Login
            </button>
          </div>
        </Field>
        <hr />
        <p className="has-text-centered">
          <a href={`${APP_URL}/users/create`}>Sign Up</a>{' '}
          <span className="has-text-dark">|</span>{' '}
          <Link to="/sign-in-help">Need help?</Link>
        </p>
      </div>
    </div>
  );
};

export default WithLocation(SignInForm);
