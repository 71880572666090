import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import * as fromStorage from '../services/storage';
import SignInForm from '../components/sign-in-form';
import PorticaLogo from '../components/portica-logo';
import SEO from '../components/seo';

import { doLogout } from '../services/user';

import CookieConsent from '../components/cookie-consent';
import { getConsent } from '../services/consent';

const SignIn = (props) => {
  let woopra = null;
  if (typeof window !== 'undefined') {
    woopra = window.woopra || null;
  }

  const params = new URLSearchParams(props.location.search);
  const [isWorking, setIsWorking] = useState(true);
  const [showCookie, setShowCookie] = useState(false);
  const [consentStatus, setConsentStatus] = useState(getConsent());
  const [returnUrl, setReturnUrl] = useState('');
  const [returnTo, setReturnTo] = useState('');

  useEffect(() => {
    const shouldLogout = params.get('logout');
    setReturnUrl(params.get('url'));
    setReturnTo(params.get('from'));

    if (shouldLogout) {
      doLogout()
        .then(() => {
          fromStorage.clearExp();
          fromStorage.clearUser();
          setIsWorking(false);
        })
        .catch(() => false);
    } else {
      setIsWorking(false);
    }
  }, []);

  useEffect(() => {
    if (consentStatus === 'update-here') {
      if (woopra) {
        woopra.track('login_accessed');
      }
    }
  }, [consentStatus]);

  const cookieUpdate = (data) => {
    setConsentStatus(data);
    setShowCookie(false);
  };

  return (
    <div>
      <Helmet>
        <script>
          {consentStatus === 'update-here'
            ? `(function(h,o,t,j,a,r){
              h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
              h._hjSettings={hjid:2930840,hjsv:6};
              a=o.getElementsByTagName('head')[0];
              r=o.createElement('script');r.async=1;
              r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
              a.appendChild(r);
          })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');`
            : 'console.log("No consent")'}
        </script>
      </Helmet>
      <SEO
        title="Sign in | Portica - All your projects in one place"
        description="Login into Portica. Portica is a all-in-one project manager, created by Architects for Architects and Design Professionals. A digital home for your projects"
      ></SEO>
      <div className="sign-in-wrapper">
        <div className="sign-in-wrapper__content">
          <PorticaLogo></PorticaLogo>
          {isWorking ? null : (
            <SignInForm url={returnUrl} from={returnTo}></SignInForm>
          )}
        </div>
      </div>
    </div>
  );
};

export default SignIn;
